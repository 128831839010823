import { Paper } from "@mui/material";
import { getStaticAsset } from "core/model/config/index";
import { Button } from "ds/ui";
import { dp, padding } from "ds_legacy/materials/metrics";
import styled from "styled-components";
import { useTranslations } from "translations";

const MAP_PLACEHOLDER_NEAR_FIELD = getStaticAsset("map-placeholder-1000.webp");

const PAPER_BORDER = dp(32);
const DEFAULT_SIZE = "100%";

const Wrapper = styled.div<{
  height: string;
  width: string;
}>`
  background-image: url("${MAP_PLACEHOLDER_NEAR_FIELD}");
  background-position: center;
  background-repeat: no-repeat;

  width: ${(props) => (props.width ? props.width : DEFAULT_SIZE)};
  height: ${(props) => (props.height ? props.height : DEFAULT_SIZE)};

  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
  }

  & > * {
    position: relative;
  }
`;

const Title = styled.p`
  text-align: center;
  padding: ${padding(1)};
`;

export default function MapActivation({
  height,
  onActivate,
  title,
  width,
}: {
  height: string;
  onActivate: () => void;
  title?: string;
  width: string;
}) {
  const translations = useTranslations();

  return (
    <Wrapper height={height} width={width}>
      <Paper
        elevation={5}
        style={{
          display: "flex",
          alignSelf: "center",
          flexFlow: "column",
          alignItems: "center",
          justifyContent: "center",
          maxWidth: `calc(100% - ${PAPER_BORDER})`,
          width: dp(246),
          height: dp(136),
          fontSize: dp(14),
          boxShadow: "none",
        }}
      >
        {title ? <Title>{title}</Title> : null}
        <Button
          id="activate-map"
          variant="ghost"
          onPress={() => onActivate()}
          color="primary"
        >
          {translations.patient.map.mapButton}
        </Button>
      </Paper>
    </Wrapper>
  );
}
