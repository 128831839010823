import lazy from "dsl/atoms/retry";
import MapPlaceholder from "dsl/organisms/CareproviderMap/Placeholder";
import { Suspense } from "react";
import { LocationMapProps, MarkerProps } from "./index";

const LocationMap = lazy(() => import("./index"));

export default function LazyLocationMap({
  height,
  width,
  ...props
}: LocationMapProps<MarkerProps>) {
  return (
    <Suspense fallback={<MapPlaceholder width={width} height={height} />}>
      <LocationMap width={width} height={height} {...props} />
    </Suspense>
  );
}
